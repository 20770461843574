
<template>
  <div>
    <v-snackbar
      v-model="showSnackBar"
      :color="appColor"
      right
      :timeout="timeout"
    >
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center v-if="appUser !== 'EmergencyUser' && appUser !== 'admin'">
      <v-flex
        xs6
        align-self-center
        pa-2
        text-center
        v-if="caseDetails.status == 'Pending' && appUser !== 'admin'"
      >
        <SelfAssign
          :createDate="createDate"
          :caseDetails="caseDetails"
          @stepper="winStepper"
        />
      </v-flex>
      <v-flex
        xs6
        align-self-center
        pa-2
        text-center
        v-else-if="
          caseDetails.status == 'Assigned' && caseDetails.isTransferred && appUser !== 'admin'
        "
      >
        <SelfAssign
          v-if="caseDetails.isTransferRequest"
          :createDate="createDate"
          :caseDetails="caseDetails"
          @stepper="winStepper"
        />
      </v-flex>
      <!-- <v-flex
        xs6
        align-self-center
        pa-2
        text-center
        v-if="caseDetails.status == 'Pending'"
      >
        <AssignTo
          :createDate="createDate"
          :caseDetails="caseDetails"
          @stepper="winStepper"
        />
      </v-flex> -->
      <!-- <v-flex
        xs6
        align-self-center
        pa-2
        text-center
        v-else-if="
          caseDetails.status == 'Assigned' && caseDetails.isTransferred
        "
      >
        <AssignTo
          v-if="caseDetails.isTransferRequest"
          :createDate="createDate"
          :caseDetails="caseDetails"
          @stepper="winStepper"
        />
      </v-flex> -->
      <!-- <v-flex
        v-if="caseDetails.status == 'Assigned' && caseDetails.isAssignedToMe &&  caseDetails.resolveDetails.length === 0 && appUser !== 'admin'" 
        xs6
        align-self-center
        text-center
        pa-2
        text-
      >
        <Resolve
          :createDate="createDate"
          :caseDetails="caseDetails"
          @stepper="winStepper"
        />
      </v-flex> -->
      <v-flex
        v-if="caseDetails.status == 'Assigned' && caseDetails.isAssignedToMe == true && appUser !== 'admin' && caseDetails.resolveDetails.length == 0" 
        xs6
        align-self-center
        text-center
        pa-2
        text-
      >
        <Resolve
          :createDate="createDate"
          :caseDetails="caseDetails"
          @stepper="winStepper"
        />
      </v-flex>
      <v-flex
        v-if="caseDetails.status == 'Assigned' && caseDetails.isAssignedToMe == true && caseDetails.resolveDetails.length > 0 && appUser !== 'admin'" 
        xs6
        align-self-center
        text-center
        pa-2
        text-
      >
        <Resolveedit
          :createDate="createDate"
          :caseDetails="caseDetails"
          @stepper="winStepper"
        />
      </v-flex>
      <!-- <v-flex
        v-if="caseDetails.status == 'Assigned' && caseDetails.isAssignedToMe"
        xs6
        align-self-center
        text-center
        pa-2
        text-
      >
        <Resolve
          :createDate="createDate"
          :caseDetails="caseDetails"
          @stepper="winStepper"
        />
      </v-flex> -->
      <!-- <v-flex
        v-if="caseDetails.isAssignedToMe && (caseDetails.releaseDetails.releasedStatus === false || caseDetails.status == 'Captured') && appUser !== 'admin' && caseDetails.status !== 'Assigned'"
        xs6
        align-self-center
        text-center
        pa-2
        text-
      >
        <Release
          :createDate="createDate"
          :caseDetails="caseDetails"
          @stepper="winStepper"
        />
      </v-flex> -->
      <v-flex
        v-if="caseDetails.isAssignedToMe &&  caseDetails.status == 'Captured' && appUser !== 'admin' && caseDetails.status !== 'Assigned'"
        xs6
        align-self-center
        text-center
        pa-2
        text-
      >
        <Release
          :createDate="createDate"
          :caseDetails="caseDetails"
          @stepper="winStepper"
        />
      </v-flex>

      <v-flex
        v-if="
          (caseDetails.status == 'Pending' && !caseDetails.sentToPublic) ||
          (caseDetails.status == 'Assigned' &&
            caseDetails.isAssignedToMe &&
            !caseDetails.sentToPublic)
        "
        xs6
        align-self-center
        pa-2
        text-center
      >
        <SendToPublic
          :sideNav="sideNav"
          :createDate="createDate"
          :caseDetails="caseDetails"
          @stepper="winStepper"
        />
      </v-flex>

      <v-flex
        v-if="
          caseDetails.status == 'Pending' ||
          (caseDetails.status == 'Assigned' && caseDetails.isAssignedToMe)
        "
        xs6
        align-self-center
        pa-2
        text-center
      >
        <CloseCase
          :createDate="createDate"
          :caseDetails="caseDetails"
          @stepper="winStepper"
        />
      </v-flex>
      <!-- <v-flex
        v-if="
          caseDetails.status == 'Assigned' &&
          caseDetails.isAssignedToMe &&
          caseDetails.isTransferable
        "
        xs6
        align-self-center
        text-center
        pa-2
        text-
      >
        <TransferCase
          :createDate="createDate"
          :caseDetails="caseDetails"
          @stepper="winStepper"
        />
      </v-flex> -->
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import SelfAssign from "./selfAssign";
// import TransferCase from "./transferCase";
// import AssignTo from "./assignTo";
import CloseCase from "./closeForm";
import Resolve from "./resolveForm";
import Resolveedit from "./resolveEditForm";
// import Resolve from "../../../views/ResolveCase/resolveCase.vue";

import Release from "./releaseForm";
import SendToPublic from "./sendToPublic";
export default {
  props: ["sideNav", "caseDetails", "createDate"],
  components: {
    Resolve,
    Release,
    CloseCase,
    SelfAssign,
    // AssignTo,
    SendToPublic,
    // TransferCase,
    Resolveedit
  },
  data() {
    return {
      closeDialogue: false,
      msg: null,
      showSnackBar: false,
      timeout: 5000,
      rules: {
        required: (value) => !!value || "Required",
      },
      remarks: null,
    };
  },
  computed: {
    form() {
      return {
        remarks: this.remarks,
      };
    },
    appUser() {
      return this.$store.state.userRole;
    },
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "assignFunction") {
        this.assign(window_data.assignType, window_data.officerId);
      } else if (window_data.type == "reportActions") {
        this.$emit("stepper", {
          type: window_data.type,
          pageResponse: window_data.response,
        });
      }
      if (window_data.type == "sideNav") {
        this.$emit("stepper", {
          type: "sideNav",
          sideNav: !this.sideNav,
        });
      }
    },
    assign(assignType) {
      // this.assignDialogue = false;
      this.appLoading = true;
      var reportData = {};
      reportData["reportId"] = this.caseDetails._id;
      reportData["assignType"] = assignType;
      axios({
        method: "POST",
        url: "/report/selfassign",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: reportData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.$emit("stepper", {
              type: "reportActions",
              pageResponse: response.data,
            });
            this.msg = response.data.msg;
            this.showSnackBar = true;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>