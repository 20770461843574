<template>
  <v-expansion-panel v-if="caseDetails.releaseDetails.releasedStatus">
    <v-expansion-panel-header expand-icon="mdi-menu-down">
      <span class="itemHeading">Released Details</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-layout wrap pt-4>
        <!-- <v-flex v-if="caseDetails.isAssignedToMe" xs12 text-right>
          <EditDetails :caseDetails="caseDetails" @stepper="winStepper" />
        </v-flex> -->
        <v-flex xs12>
          <v-layout pt-2 wrap>
            <v-flex xs9>
              <v-layout wrap>
                <v-flex xs12>
                  <span
                    style="
                      font-family: sofiaProLight;
                      font-size: 13px;
                      color: #4d4d4d;
                    "
                    >Released By</span
                  ><br />
                  <span
                    style="
                      font-family: sofiaProRegular;
                      font-size: 12px;
                      color: #000;
                      line-height: 0.5;
                    "
                  >
                    <span
                      v-if="
                        caseDetails.releaseDetails.releasedBy.role === 'User'
                      "
                    >
                      <span>{{
                        caseDetails.releaseDetails.releasedBy.userId.name
                      }}</span>
                    </span>
                    <span
                      v-else-if="
                        caseDetails.releaseDetails.releasedBy.role === 'MASTER'
                      "
                    >
                      <span
                        >{{
                          caseDetails.releaseDetails.releasedBy.admin
                            .masterName
                        }}&nbsp;(Admin)</span
                      >
                    </span>
                    <span
                      v-else-if="
                        caseDetails.releaseDetails.releasedBy.role ===
                        'EmergencyUser'
                      "
                    >
                      <span
                        >{{
                          caseDetails.releaseDetails.releasedBy.userId.name
                        }}&nbsp;(Emergency User)</span
                      >
                    </span>
                    <span
                      v-else-if="
                        caseDetails.releaseDetails.releasedBy.role ===
                        'RANGE'
                      "
                    >
                      <span
                        >{{
                          caseDetails.releaseDetails.releasedBy.range.accessCredentials.username
                        }}&nbsp;(Range Officer)</span
                      >
                    </span>
                    <span
                      v-else-if="
                        caseDetails.releaseDetails.releasedBy.role ===
                        'DIVISION'
                      "
                    >
                      <span
                        >{{
                          caseDetails.releaseDetails.releasedBy.division.accessCredentials.username
                        }}&nbsp;(Division Officer)</span
                      >
                    </span>
                    <span
                      v-else-if="
                        caseDetails.releaseDetails.releasedBy.role ===
                        'RRT'
                      "
                    >
                      <span
                        >{{
                          caseDetails.releaseDetails.releasedBy.userId.name
                        }}&nbsp;(RRT)</span
                      >
                    </span>
                    <span v-else>UnKnown </span>
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3>
              <span
                class="float-right"
                v-if="caseDetails.releaseDetails.releasedTime"
                style="
                  font-family: sofiaProRegular;
                  color: #000;
                  font-size: 10px;
                "
              >
                {{ timeSince(caseDetails.releaseDetails.releasedTime) }}
              </span>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs12 md6 sm6 lg6>
              <span
                style="
                  font-family: sofiaProLight;
                  font-size: 13px;
                  color: #4d4d4d;
                "
                >Released Location</span
              ><br />
              <span
                style="
                  font-family: sofiaProRegular;
                  font-size: 12px;
                  color: #000;
                  line-height: 0.5;
                "
              >
                <span v-if="caseDetails.releaseDetails.locationName">{{
                  caseDetails.releaseDetails.locationName
                }}</span>
                <span v-else>UnKnown </span>
              </span>
            </v-flex>

            <v-flex xs12 md6 sm6 lg6>
              <span
                style="
                  font-family: sofiaProLight;
                  font-size: 11px;
                  color: #4d4d4d;
                "
                >Is Delayed?</span
              ><br />
              <span
                style="
                  font-family: sofiaProRegular;
                  font-size: 12px;
                  color: #000;
                  line-height: 0.5;
                "
                ><span v-if="caseDetails.releaseDetails">
                  <span v-if="caseDetails.releaseDetails.isDelayed === true"
                    >Yes</span
                  >
                  <span
                    v-else-if="caseDetails.releaseDetails.isDelayed === false"
                    >No</span
                  >
                </span>
              </span>
            </v-flex>

            <v-flex
              xs12
              md6
              sm6
              lg6
              v-if="caseDetails.releaseDetails.isDelayed"
            >
              <span
                style="
                  font-family: sofiaProLight;
                  font-size: 11px;
                  color: #4d4d4d;
                "
                >Delayed Reason</span
              ><br />
              <span
                style="
                  font-family: sofiaProRegular;
                  font-size: 12px;
                  color: #000;
                  line-height: 0.5;
                "
              >
                <span
                  v-if="
                    caseDetails.releaseDetails.isDelayed &&
                    caseDetails.releaseDetails.delayreason
                  "
                  >{{ caseDetails.releaseDetails.delayreason }}</span
                >
                <span v-else>- </span>
              </span>
            </v-flex>

            <v-flex xs12 md6 sm6 lg6>
              <span
                style="
                  font-family: sofiaProLight;
                  font-size: 11px;
                  color: #4d4d4d;
                "
                >Animal Health</span
              ><br />
              <span
                style="
                  font-family: sofiaProRegular;
                  font-size: 12px;
                  color: #000;
                  line-height: 0.5;
                "
              >
                <span v-if="caseDetails.releaseDetails.animalHealthId">{{
                  caseDetails.releaseDetails.animalHealthId.name
                }}</span>
                <span v-else>- </span>
              </span>
            </v-flex>

            <v-flex xs12 md6 sm6 lg6>
              <span
                style="
                  font-family: sofiaProLight;
                  font-size: 11px;
                  color: #4d4d4d;
                "
                >Mishaps</span
              ><br />
              <span
                style="
                  font-family: sofiaProRegular;
                  font-size: 12px;
                  color: #000;
                  line-height: 0.5;
                "
              >
                <span v-if="caseDetails.releaseDetails.mishaps">{{
                  caseDetails.releaseDetails.mishaps
                }}</span>
                <span v-else>- </span>
              </span>
            </v-flex>

            <v-flex xs12 md6 sm6 lg6>
              <span
                style="
                  font-family: sofiaProLight;
                  font-size: 11px;
                  color: #4d4d4d;
                "
                >Remarks</span
              ><br />
              <span
                style="
                  font-family: sofiaProRegular;
                  font-size: 12px;
                  color: #000;
                  line-height: 0.5;
                "
              >
                <span v-if="caseDetails.releaseDetails.remarks">{{
                  caseDetails.releaseDetails.remarks
                }}</span>
                <span v-else>- </span>
              </span>
            </v-flex>

            <v-flex
              xs12
              v-if="
                caseDetails.releaseDetails &&
                caseDetails.releaseDetails.releaseImages.length > 0
              "
            >
              <span
                style="
                  font-family: sofiaProLight;
                  font-size: 11px;
                  color: #4d4d4d;
                "
                >Released Images</span
              >
            </v-flex>
            <v-flex
              xs12
              v-if="
                caseDetails.releaseDetails &&
                caseDetails.releaseDetails.releaseImages.length > 0
              "
            >
              <OwlCarousel
                :nav="false"
                :dots="true"
                :responsive="owlResponsive"
              >
                <template
                  v-for="(item, i) in caseDetails.releaseDetails.releaseImages"
                >
                  <CarouselImage :key="i" v-if="item" v-bind:storage="item" />
                </template>
              </OwlCarousel>
            </v-flex>

            <!-- <v-flex xs12 >
              <v-carousel v-model="slideNo" show-arrows-on-hover hide-delimiter-background :interval="slideInterval" continuous cycle>
                <v-carousel-item
                  v-for="(item, i) in caseDetails.releaseDetails.releaseImages" :key="i">
                  <v-card flat >
                    <v-img :src="mediaURL + item" width="100%" contain alt="item" height="100%" fill-height>
                    </v-img>
                  </v-card>
              </v-carousel-item>
              </v-carousel>
            </v-flex> -->

            <!-- <v-flex xs12 v-if="caseDetails.releaseDetails.releaseTypeId">
              <v-layout
                v-if="
                  caseDetails.releaseDetails.releaseTypeId._id ==
                  '60a7ee0af637aa1ddb5f964a'
                "
                pt-2
                wrap
              >
                <v-flex xs2>
                  <v-avatar
                    v-if="caseDetails.releaseDetails.handoverTo"
                    size="40px"
                  >
                    <img
                      alt="Avatar"
                      :src="
                        mediaURL + caseDetails.releaseDetails.handoverTo.photo
                      "
                    />
                  </v-avatar>
                </v-flex>

                <v-flex xs7>
                  <v-layout wrap>
                    <v-flex xs12>
                      <span
                        style="
                          font-family: sofiaProLight;
                          font-size: 13px;
                          color: #4d4d4d;
                        "
                        >Handover To</span
                      ><br />
                      <span
                        style="
                          font-family: sofiaProRegular;
                          font-size: 12px;
                          color: #000;
                          line-height: 0.5;
                        "
                      >
                        <span v-if="caseDetails.releaseDetails.handoverTo">{{
                          caseDetails.releaseDetails.handoverTo.fullName
                        }}</span>
                        <span v-else>UnKnown </span>
                      </span>
                      <br />
                      <span
                        v-if="caseDetails.releaseDetails.handoverTo"
                        style="
                          font-family: sofiaProRegular;
                          font-size: 12px;
                          color: #000;
                        "
                      >
                        {{ caseDetails.releaseDetails.handoverTo.phone }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex> -->
          </v-layout>
        </v-flex>
      </v-layout>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
// import EditDetails from "./editResolvedDetails";
import CarouselImage from "@/components/Cases/resolveImages";

export default {
  props: ["caseDetails"],
  components: { CarouselImage },

  data() {
    return {
      owlResponsive: {
        0: { items: 1, nav: false },
        600: { items: 1, nav: false },
        960: { items: 1, nav: false },
        1264: { items: 1, nav: false },
        1920: { items: 1, nav: false },
      },
      slideInterval: 3000,
      slideNo: 0,
    };
  },

  methods: {
    winStepper(window_data) {
      if (window_data.type == "reportActions") {
        this.$emit("stepper", {
          type: window_data.type,
        });
      }
    },
    timeSince(time) {
      switch (typeof time) {
        case "number":
          break;
        case "string":
          time = +new Date(time);
          break;
        case "object":
          if (time.constructor === Date) time = time.getTime();
          break;
        default:
          time = +new Date();
      }
      var time_formats = [
        [60, "seconds", 1], // 60
        [120, "1 minute ago", "1 minute from now"], // 60*2
        [3600, "minutes", 60], // 60*60, 60
        [7200, "1 hour ago", "1 hour from now"], // 60*60*2
        [86400, "hours", 3600], // 60*60*24, 60*60
        [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
        [604800, "days", 86400], // 60*60*24*7, 60*60*24
        [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
        [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
        [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
        [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
        [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
      ];
      var seconds = (+new Date() - time) / 1000,
        token = "ago",
        list_choice = 1;

      if (seconds == 0) {
        return "Just now";
      }
      if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = "from now";
        list_choice = 2;
      }
      var i = 0,
        format;
      while ((format = time_formats[i++]))
        if (seconds < format[0]) {
          if (typeof format[2] == "string") return format[list_choice];
          else
            return (
              Math.floor(seconds / format[2]) + " " + format[1] + " " + token
            );
        }
      return time;
    },
  },
};
</script>