<template>
  <div>
    <v-snackbar
      v-model="showSnackBar"
      :color="appColor"
      right
      :timeout="timeout"
    >
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-card
          @click="closeDialogue = true"
          elevation="2"
          :ripple="false"
          style="cursor: pointer"
          tile
          class="pa-2"
        >
          <span
            style="font-family: sofiaProSemiBold; color: #000; font-size: 14px"
          >
            Close Case
          </span>
        </v-card>
        <v-dialog
          v-model="closeDialogue"
          :width="
            $vuetify.breakpoint.name == 'xs' ||
            $vuetify.breakpoint.name == 'sm' ||
            $vuetify.breakpoint.name == 'md'
              ? '100vw'
              : $vuetify.breakpoint.name == 'lg'
              ? '35vw'
              : '50vw'
          "
        >
          <v-card>
            <v-layout pa-4 wrap justify-center pa-8>
              <v-flex xs10 text-center>
                <v-layout wrap>
                  <v-flex xs11>
                    <v-icon size="35" :color="appColor"
                      >mdi-check-circle-outline</v-icon
                    >
                    <span class="pl-2 itemSubHeading">Case Closed</span>
                  </v-flex>
                  <v-flex xs12 pt-5>
                    <v-btn
                      x-small
                      depressed
                      dense
                      :ripple="false"
                      color="#EdEdEd"
                      style="letter-spacing: 0px"
                      ><span
                        v-if="caseDetails.animalId"
                        style="
                          font-family: sofiaProRegular;
                          color: #000;
                          font-size: 10px;
                        "
                        >{{ caseDetails.animalId.name }}
                      </span></v-btn
                    >
                  </v-flex>
                  <v-flex xs12 pb-4>
                    <span
                      v-if="caseDetails.locationname"
                      style="
                        font-family: sofiaProRegular;
                        font-size: 13px;
                        color: #000;
                      "
                    ><span>Location:</span>
                      {{ caseDetails.locationname }}
                    </span><br>
                    <span
                      v-if="formattedReportDate"
                      style="
                        font-family: sofiaProRegular;
                        font-size: 13px;
                        color: #000;
                      "
                    ><span>Reported Date:</span>
                      {{ formattedReportDate }}
                    </span>
                  </v-flex>
                  <v-flex xs12>
                    <v-form @submit.prevent="validateInput">
                      <!-- <v-select
                        v-model="closeStatusId"
                        placeholder="Status"
                        :items="closeStatus"
                        item-text="name"
                        item-value="_id"
                        class="formClass"
                        color="#B1B1B1"
                        solo
                        dense
                        :rules="[rules.required]"
                      ></v-select> -->
                      <v-textarea
                        solo
                        rows="4"
                        v-model="remarks"
                        style="font-family: sofiaProRegular; font-size: 12px"
                        placeholder="Add Remark"
                        :rules="[rules.required]"
                      ></v-textarea>
                    </v-form>
                  </v-flex>
                  <v-flex xs6 offset-3>
                    <v-btn
                      block
                      small
                      :color="appColor"
                      dark
                      :ripple="false"
                      depressed
                      type="submit"
                      @click="validateInput()"
                    >
                      <span class="text-capitalize" style=""> SUBMIT </span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["caseDetails", "createDate"],
  data() {
    return {
      closeDialogue: false,
      msg: null,
      showSnackBar: false,
      timeout: 5000,
      rules: {
        required: (value) => !!value || "Required",
      },
      remarks: null,
      closeStatusId: null,
      actions: [
        {
          action: "Assign To",
          value: "assignTo",
        },
        {
          action: "Close Cases",
          value: "closeCase",
        },
      ],
      closeStatus: [],
    };
  },
  computed: {
    form() {
      return {
        remarks: this.remarks,
        closeStatusId: this.closeStatusId,
      };
    },
    formattedReportDate() {
  if (this.caseDetails.reportTime) {
    const date = new Date(this.caseDetails.reportTime);
    const day = date.getDate().toString().padStart(2, '0'); // Get day and ensure it's 2 digits
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month (0-indexed, so add 1) and ensure it's 2 digits
    const year = date.getFullYear(); // Get full year

    return `${day}/${month}/${year}`; // Return formatted date
  }
  return null;
},
  },
  mounted() {
    // this.getcloseStatus();
  },
  methods: {
    validateInput() {
     
      if (!this.remarks){
        this.msg = "Please Provide remarks";
        this.showSnackBar = true;
        return;
      }
      this.closeCase();
    },
    // getcloseStatus() {
    //   this.appLoading = true;

    //   axios({
    //     method: "GET",
    //     url: "/closeStatus/list",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status == true) {
    //         this.closeStatus = response.data.data;
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showSnackBar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       console.log(err);
    //     });
    // },
    closeCase() {
      this.closeDialogue = false;
      this.appLoading = true;
      var reportData = {};
      reportData["reportId"] = this.caseDetails._id;
      reportData["remarks"] = this.remarks;
      axios({
        method: "POST",
        url: "/report/close",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: reportData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.$emit("stepper", {
              type: "reportActions",
              pageResponse: response.data,
            });

            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
