<template>
  <div>
    <v-snackbar
      v-model="showSnackBar"
      :color="appColor"
      right
      :timeout="timeout"
    >
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-card
          @click="sideNavStepper"
          elevation="3"
          :ripple="false"
          style="cursor: pointer"
          tile
          class="pa-2"
        >
          <span
            style="font-family: sofiaProSemiBold; color: #000; font-size: 14px"
          >
            Send To Public
          </span>
        </v-card>
        <!-- <v-navigation-drawer
      v-model="sideNav"
      absolute
      right
      width="350px"
      color="#FAFAFA"
    >
      <v-layout wrap justify-center>
        <v-flex xs12 text-center pa-2 pl-4 align-self-center>
          <template>
            <v-layout wrap>
              <v-flex xs1>
                <v-icon color="#0071E3">mdi-account-group</v-icon>
              </v-flex>
              <v-flex xs8>
                <span style="font-family: sofiaProMedium; font-size: 16px"
                  >Users Nearby this Conflict</span
                >
                <v-layout wrap>
                  <v-flex xs6>
                    <span
                      style="
                        color: #6e6e6e;
                        font-family: sofiaProRegular;
                        font-size: 13px;
                      "
                      >8 Active Users</span
                    >
                  </v-flex>
                  <v-flex xs6>
                    <span
                      style="
                        color: #6e6e6e;
                        font-family: sofiaProRegular;
                        font-size: 13px;
                      "
                      >7 Offline Users</span
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
            <v-flex xs2 text-right  @click="sideNav = false"  class="hidden-md-and-up" >
              <v-icon
                >mdi-close</v-icon
              >
            </v-flex>
            </v-layout>
          </template>
          <v-layout pt-4>
            <v-divider></v-divider>
          </v-layout>
          <v-layout wrap justify-center align-center>
            <v-flex xs5>
              <v-btn
                block
                small
                width="30px"
                light
                :ripple="false"
                color="#FFF"
                depressed
                rounded
              >
                <span style="font-family: sofiaProMedium; font-size: 13px">
                  8 Active Users
                </span>
              </v-btn>
            </v-flex>
            <v-flex xs4 offset-3>
              <v-radio-group v-model="radioGroup">
                <v-radio
                  v-model="radioGroup"
                  :ripple="false"
                  label="Select All"
                  value="1"
                  color="#5CA067"
                  style="font-family: sofiaProBold; font-size: 10px"
                ></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
          <v-layout wrap v-for="(item, index) in items" :key="index" pt-3>
            <v-flex xs12>
              <v-layout wrap>
                <v-flex xs3>
                  <v-avatar size="40px">
                    <img :src="item.avatar" />
                  </v-avatar>
                </v-flex>
                <v-flex xs9 text-left>
                  <span style="font-family: sofiaProMedium; font-size: 14px">{{
                    item.title
                  }}</span
                  ><br />
                  <span style="font-family: sofiaProRegular; font-size: 12px">{{
                    item.subtitle
                  }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout py-4>
            <v-divider></v-divider>
          </v-layout>
          <v-layout wrap>
            <v-flex xs6>
              <v-btn
                block
                small
                light
                :ripple="false"
                color="#FFF"
                depressed
                rounded
              >
                <span style="font-family: sofiaProMedium; font-size: 13px">
                  8 Active Users
                </span>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout wrap pt-3>
            <v-flex xs12 text-left>
              <GroupAvatar
                borderColor="transparent"
                :avatars="avatars"
                :max="max"
              ></GroupAvatar>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center align-center>
            <v-flex>
              <v-radio-group v-model="radioGroup">
                <v-radio
                  v-model="radioGroup"
                  :ripple="false"
                  label="Select All"
                  value="1"
                  dense
                  color="#5CA067"
                  style="font-family: sofiaProBold; font-size: 10px"
                ></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex>
              <v-btn
                block
                small
                light
                :ripple="false"
                :color="appColor"
                depressed
              >
                <span
                  style="
                    font-family: sofiaProMedium;
                    font-size: 13px;
                    color: #fff;
                  "
                >
                  SEND
                </span>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout align-start  class="hidden-sm-and-down" >
            <v-flex xs1 align-self-end>
              <v-icon @click="sideNav = false"
                >mdi-arrow-left-bold-circle-outline</v-icon
              >
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-navigation-drawer> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
// import "vue-group-avatar/dist/vue-group-avatar.css";
// import { GroupAvatar } from "vue-group-avatar";
export default {
  props: ["sideNav", "caseDetails", "createDate"],
  components: {
    // GroupAvatar,
  },
  data() {
    return {
      officials: [],
      msg: null,
      showSnackBar: false,
      timeout: 5000,
      radioGroup: 1,
      max: 3,
      avatars: [
        "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      ],
      items: [
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Tom Johen Joseph",
          subtitle: "Near By",
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Tom Jouhn  Joseph",
          subtitle: "Near By",
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Tom John Joseph",
          subtitle: "Near By",
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Tom uJouuhn Joseph",
          subtitle: "Near By",
        },
      ],
    };
  },
  mounted() {
    // this.getUsers();
  },
  methods: {
    sideNavStepper() {
      this.$emit("stepper", {
        type: "sideNav",
        sideNav: !this.sideNav,
      });
    },
    assign(assignType, officerId) {
      this.assignDialogue1 = false;
      this.assignDialogue3 = false;
      this.$emit("stepper", {
        type: "assignFunction",
        assignType: assignType,
        officerId: officerId,
      });
    },
    getUsers() {
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/report/users/nearby",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: { reportId: this.caseDetails._id },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.officials = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>